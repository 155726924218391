
function initModal() {
  // Bootstrap - Open Another Modal in Modal
  $(document).on('show.bs.modal', (event) => {
    if (!event.relatedTarget) {
      $('.modal').not(event.target).modal('hide');
    }
    if ($(event.relatedTarget).parents('.modal').length > 0) {
      $(event.relatedTarget).parents('.modal').modal('hide');
    }
  });

  $(document).on('shown.bs.modal', (event) => {
    if ($('body').hasClass('modal-open') == false) {
      $('body').addClass('modal-open');
    }
  });
}

function autoPlayYouTubeModal() {
  $(document).on('show.bs.modal', (event) => {
    if (event.relatedTarget) {
      const $modal = $(event.target);
      const videoSrc = $(event.relatedTarget).attr('data-video-src');
      const videoSrcAuto = videoSrc + '?autoplay=1';
      console.log(videoSrcAuto);
      $('iframe', $modal).attr('src', videoSrcAuto);

      $modal.on('hidden.bs.modal', function (e) {
        $('iframe', $modal).attr('src', videoSrc);
      });
    }
  });
}

export default function initBootstrap() {
  // Bootstrap - Tabs
  $().tab;

  // Bootstrap - Popover
  $('[data-toggle="popover"]').popover();

  // Bootstrap - Tooltip
  $('[data-toggle="tooltip"]').tooltip();

  // Bootstrap - Modal
  initModal();
  autoPlayYouTubeModal();
}
