'use strict';
import initBootstrap from './bootstrap.init';

window.$ = jQuery;

$(() => {
  
  // Bootstrap init
  initBootstrap();

  // Toggle class
  $('[data-toggle-class]').on('click', (e) => {
    e.preventDefault();
    const $this = $(e.currentTarget);
    const toogleClass = $this.attr('data-toggle-class');
    const toogleElement = $this.attr('data-toogleElement') || 'body';
    $(toogleElement).toggleClass(toogleClass);
  });

  // Copy to clipboard
  $('[data-copy-to-clipboard]').on('click', (e) => {
    e.preventDefault();
    const $this = $(e.currentTarget);
    const $element = $($this.attr('data-copy-to-clipboard'));
    $element.attr('disabled', false);
    $element.select();
    document.execCommand('copy');
    $element.attr('disabled', true);
  });


  // Video Alert
  $('[data-show-video-alert]').on('click', (e) => {
    e.preventDefault();
    const $this = $(e.currentTarget);
    const size = $this.attr('data-show-video-alert');
    swal({
      type: 'info',
      title: 'Twójlimit przypomina',
      html: 'Oglądanie jest formą pobierania. Z Twojego konta zostanie pobrany transfer <strong>' + size + '</strong>',
      showCloseButton: true,
      showCancelButton: true,
      reverseButtons: true,
      confirmButtonClass: 'btn btn-primary',
      cancelButtonClass: 'btn',
      buttonsStyling: false,
      cancelButtonText: 'Anuluj',
      confirmButtonText: 'OK',
      width: '383px'
    }).then(function () {
      swal(
        {
          type: 'success',
          title: 'Kliknięto ok',
          text: 'Zamiast popupa akcja przejścia do oglądania video',
          timer: 200000,
          showConfirmButton: false,
        }
      );
    });
  });

  // Show password
  $('[data-show-password]').on('click', (e) => {
    e.preventDefault();
    const $this = $(e.currentTarget);
    const $input = $($this.attr('data-show-password'));
    const newText = $this.attr('data-toggle-text');
    const currentText = $this.text();

    const newType = ($input.attr('type') === 'password') ? 'text' : 'password';
    $input.attr('type', newType);
    $this.text(newText);
    $this.attr('data-toggle-text', currentText);

  });

  $('.testimonials-home').slick({
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    // centerMode: true,
    focusOnSelect: true,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 5000,
    centerPadding: '0',

    responsive: [
      {
        breakpoint: 768,
        settings: {
          arrows: false,
          centerMode: true,
          centerPadding: '40px',
          slidesToShow: 3
        }
      },
      {
        breakpoint: 560,
        settings: {
          arrows: false,
          centerMode: true,
          centerPadding: '40px',
          slidesToShow: 1
        }
      }
    ]

  });
  
  // payment card
  
  $('#card-form').card({
      container: '.card-wrapper'
  });
  
  // clocks / countdowns
  
  let predictorClock = $("#predictor-clock").FlipClock(100, {
      clockFace: 'Counter',
      countdown: true
  });
    
  let promoClock = $("#promo-clock").FlipClock(100000, {
      clockFace: 'Counter',
      countdown: true
  });
  
  setTimeout(() => {
    setInterval(() => {
      predictorClock.decrement();
      promoClock.decrement();
    }, 1000);
  })
    
  // autocomplete
    
  $('#search-phrase').easyAutocomplete({
      url: function(phrase) {
        return "https://betatl.twojlimit.pl/search?query=" + phrase;
      },
      getValue: function(element) {
        return element.filename;
      },
      list: {
          onSelectItemEvent: function() {
              var filename = $("#search-phrase").getSelectedItemData().filename;
              console.log(filename);
          }
      }
  });
  

});
